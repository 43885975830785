<template lang='pug'>
v-card(v-if="coefficientById && Object.keys(coefficientById).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`${Object.keys(coefficientById?.behavior)[0]}-backOfficeCoefficient`, { id: coefficientById?.id })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="coefficientById?.behavior?.viewInfoBlock" :coefficientById="coefficientById")
    Edit(v-if="coefficientById?.behavior?.viewEditBlock" :coefficientById="coefficientById")
    //- ViewPhotoList(
    //-   v-else-if="coefficientById?.behavior?.viewFilesBlock"
    //-   isHiddenActionsButton
    //-   :sailorDocument="coefficientById"
    //-   documentType="coefficientById")
</template>
<script>
import { viewDetailedComponent, deleteConfirmation } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
// import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
export default {
  name: 'DocuemntcoefficientById',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue'),
    Edit: () => import('./Form.vue')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.coefficientById, 'viewInfoBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.coefficientById, 'viewEditBlock'),
          checkAccess: () => new Date(this.coefficientById.date_start) > new Date() && checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => new Date(this.coefficientById.date_start) > new Date() && checkAccess('backoffice'),
          color: 'red',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'red', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'coefficients-backoffice', query: { ...this.$route.query } }),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      coefficientById: state => state.backoffice.coefficientById,
      isLoading: state => state.backoffice.isLoading
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getCoefficientById', 'deleteCoefficientById']),
    async getData () {
      await this.getCoefficientById({ ...this.$route.params })
    },
    deleteDocument () {
      deleteConfirmation(this).then(async confirmation => {
        if (confirmation) {
          const response = await this.deleteCoefficientById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) this.$router.push({ name: 'coefficients-backoffice' })
        }
      })
    }
  }
}
</script>
